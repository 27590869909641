import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import * as Yup from 'yup'

import { Box, TextField, FormHelperText, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { postSearchByEmail, postDeleteByEmail } from '@services/postservice'

import LoadingSkeleton from '@objects/loadingSkeleton'
import WarningNote from '@objects/warningnote'
import PostDataTable from '@components/postdataTable'
import useDialog from '@hooks/useDialog'
import useSnackbar from '@hooks/useSnackbar'

export const frontmatter = {
  title: 'Suche',
}

const useStyles = makeStyles((theme) => ({
  searchArea: {
    marginTop: theme.spacing(1),
  },
  textField: {
    width: '30%',
  },
  button: {
    width: '20px',
    height: '50px',
    marginLeft: theme.spacing(1),
  },
  deleteArea: {
    marginTop: theme.spacing(2),
  },
  deleteButton: {
    width: '120px',
    height: '40px',
    marginLeft: theme.spacing(3),
  },
  hideComponent: {
    display: 'none',
  },
}))

function SearchPage() {
  const intl = useIntl()
  const { toggleSnackbar } = useSnackbar()
  const classes = useStyles()
  const dial = useDialog()

  const isMountedRef = useRef(null)
  const [postList, setPostList] = useState([])
  const [loading, setLoading] = useState(true)
  const [emailValidation, setEmailValidation] = useState('')
  const [showTable, setShowTable] = useState(false)
  const [hasItems, setHasItems] = useState(false)
  const [textFieldValue, setTextFieldValue] = useState('')
  const searchValueRef = useRef('')

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(),
  })

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  function getPosts(offset = 0) {
    if (!isMountedRef.current) return
    setLoading(true)
    postSearchByEmail(searchValueRef.current).then((data) => {
      if (!data.apiError) {
        const dataArray = data.items
        if (isMountedRef.current) {
          setHasItems(data.items.length)
          setPostList(dataArray)
          setLoading(false)
        }
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
    })
  }

  function doSearch() {
    const searchValue = textFieldValue
    if (searchValue !== '') {
      searchValueRef.current = searchValue
      setEmailValidation('')
      validationSchema
        .isValid({ email: searchValueRef.current })
        .then(function (valid) {
          if (valid) {
            setShowTable(true)
            setHasItems(false)
            getPosts()
          } else {
            setEmailValidation('Bitte schreiben Sie eine gültige E-Mail')
          }
        })
    }
  }
  function doDelete() {
    dial.openDialog('delete', {
      onSubmit: onDelete,
      authorEmail: searchValueRef.current,
    })
  }

  function showMsg() {
    toggleSnackbar(
      intl.formatMessage({
        id: 'dialog.author.deleted',
      }),
      'success'
    )
  }

  function onDelete({ shouldDelete }) {
    if (shouldDelete) {
      postDeleteByEmail(searchValueRef.current).then((data) => {
        if (!data.apiError) {
          showMsg()
          setShowTable(false)
          setHasItems(false)
          searchValueRef.current = ''
          setTextFieldValue('')
        } else {
          toggleSnackbar(
            `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
            'error'
          )
        }
      })
    }
  }

  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'overview.introtext',
        })}
      />
      <FormHelperText>
        <FormattedMessage id="search.email" />
      </FormHelperText>
      <div>{emailValidation}</div>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        className={classes.searchArea}
      >
        <TextField
          className={classes.textField}
          variant="outlined"
          label="E-Mail"
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
        />
        <Button
          onClick={doSearch}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <FormattedMessage id="button.search" />
        </Button>
      </Box>
      <LoadingSkeleton
        loading={loading}
        type="table"
        className={classNames({ [classes.hideComponent]: !showTable })}
      >
        <PostDataTable
          showUnconfirmed={true}
          postData={postList}
          onActionClick={getPosts}
        />
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          className={classNames(classes.deleteArea, {
            [classes.hideComponent]: !hasItems,
          })}
        >
          <FormHelperText>
            <FormattedMessage id="search.delete.warning" />
          </FormHelperText>
          <Button
            onClick={doDelete}
            variant="contained"
            color="primary"
            className={classes.deleteButton}
          >
            <FormattedMessage id="button.deleteAuthor" />
          </Button>
        </Box>
      </LoadingSkeleton>
    </>
  )
}

SearchPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default SearchPage
